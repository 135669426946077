import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Quick_Installation/NavButtons';
import ForumBox from 'components/Quick_Installation/Live_Video/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "F-Secure Internet Security",
  "path": "/Quick_Installation/Live_Video/Troubleshooting/F-Secure/",
  "dateChanged": "2017-11-24",
  "author": "Mike Polinowski",
  "excerpt": "Set an Exception for your IP Camera",
  "image": "./QI_SearchThumb_LiveVideo_F-Secure.png",
  "social": "/images/Search/QI_SearchThumb_LiveVideo_F-Secure.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_QI-Live_Video_white.webp",
  "chapter": "Installation"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='F-Secure' dateChanged='2017-11-22' author='Mike Polinowski' tag='INSTAR IP Camera' description='Use the Flash Video Plugin to view your Camera`s Live Video' image='/images/Search/QI_SearchThumb_LiveVideo.png' twitter='/images/Search/QI_SearchThumb_LiveVideo.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Schnell_Installation/Live_Video/Problembehebung/F-Secure/' locationFR='/fr/Quick_Installation/Live_Video/Troubleshooting/F-Secure/' crumbLabel="F-Secure" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "set-an-exception-rule-inside-your-f-secure-antivirus-software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#set-an-exception-rule-inside-your-f-secure-antivirus-software",
        "aria-label": "set an exception rule inside your f secure antivirus software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Set an Exception rule inside your F-Secure Antivirus Software`}</h2>
    <p>{`In some cases, it can be that the F-Secure firewall is blocking the video stream of the camera. Actually it blocks the ActiveX and Quicktime Plugin as well as our InstarVision Surveillance Center from receiving the cameras video stream. In this case you will need to deactivate the Advanced Network Protection in F-Secure so it does not block the camera anymore.`}</p>
    <p><strong parentName="p">{`Step 1`}</strong>{`: Please open the F-Secure program window by clicking the F-Secure symbol on the right side of the windows task bar. Another way is to open the start menu and to click on the program shortcut. Once opened please click Settings on the right side of the window.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/fd8f3f6ddfe71adeac17688e89a612f6/0a47e/F-Secure_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAC4klEQVQoz4WQX0hTcRTHf/tNtjm33c1MyMw/sVwUClH5EEXvvisVmGnT1FaghAvRSpICIYp6UNLSModOzd1blKlMU3PqQ2rD6RYWpt7pvbtXx9aa2737xTaT6KUP5+Eczvl+D3xBw6Mnhvb2TkMH0d1J4CZiB/zfwnGit4fo7ca7u/Aeo6m7q6+vD8gxTITFi1UJoqwzQrkKxsRAcSyUxEGpHMYpdkosCY8Hs2DGCajJFqqPwfRMhVIFBAIBkKhAbAJIPwlEsQIABDAmLJbFC6QYkCoFEhnE9sLEVJisgUdPwayzMOM4SFLHKTCQl5erK71Scf1a5dXSPTmFIP+uIL8enLsFcnSgqAFcrAfah+DIaQAAgBBEEAqF8fsypfJEMD09jRAKIcT7/Y/tTj2DKleQ1u4vmdy44UTVq8FKGmmb+7QXcrXFJZeLiooKL5UUl9Tda8o7XwimpqZ8v7bti461lR82q33l63eEkG+L9TGUe33NtbrsoZw+9xbLbrIs69/eDn8KhRAK9Q8MgpmZz1ab40Hjqw+Dn+qedWYP3FneZli/l3RRGxS9RpLrFEXRNEmSFEUFgkGe54PBIEJoeHQczM7OfrHa9LcbXhP9Q2PmaksrHfBscj9dWwzjYqgINE27XDTDMF6v1+Px8Dy/I56cnEQI0RvOYfPQt6Ul3htACPGhEM/z3F+ExyDHc1x0sSO2WCwIIY7jA4FA1DKE/kP0bGRsHExMTETEXHQRCQO53W6SJJ1OJxmBYVmfc52dt20u2tmFRde8jVtdG/k4Gk5712y3IQiirKyspqamtrZWp9O1NDePtndUFBTU66vu62/qy8tbqvSjQ2ZgMBgcDsfc3Jz1DwsLC2az2Wg0mnDcZDIZjcb3A4NjxBtTY9Pb563vWtvwp80jHYYXbS+BTCYTiUTiKBKJOCn9kOawRq2WY5giVZN8ICUteX9cSkZCmjolNUWmVMowTKZUShUKTKn6DXbpA8WQmJEoAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fd8f3f6ddfe71adeac17688e89a612f6/e4706/F-Secure_01.avif 230w", "/en/static/fd8f3f6ddfe71adeac17688e89a612f6/d1af7/F-Secure_01.avif 460w", "/en/static/fd8f3f6ddfe71adeac17688e89a612f6/56a33/F-Secure_01.avif 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/fd8f3f6ddfe71adeac17688e89a612f6/a0b58/F-Secure_01.webp 230w", "/en/static/fd8f3f6ddfe71adeac17688e89a612f6/bc10c/F-Secure_01.webp 460w", "/en/static/fd8f3f6ddfe71adeac17688e89a612f6/e88ff/F-Secure_01.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fd8f3f6ddfe71adeac17688e89a612f6/81c8e/F-Secure_01.png 230w", "/en/static/fd8f3f6ddfe71adeac17688e89a612f6/08a84/F-Secure_01.png 460w", "/en/static/fd8f3f6ddfe71adeac17688e89a612f6/0a47e/F-Secure_01.png 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/fd8f3f6ddfe71adeac17688e89a612f6/0a47e/F-Secure_01.png",
              "alt": "F-Security Internet Security",
              "title": "F-Security Internet Security",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Step 2`}</strong>{`: Go to the Firewall section in the Security Settings and un-check the option to not allow applications to download harmful files under the Advanced Network Protection.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a9d6e0bfa90059bb24e1173e529f2b16/0a47e/F-Secure_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72.60869565217392%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACTklEQVQ4y42SzW8SQRiH+f+k9SDItd696Ml4MCZGvTUmTerBuwe18WZiPBgPNi21xtqkQlkKbIEF9mPYZT/ZhcfM8JG20sY3eTIzuzO/9515f7mNjXusr9/mVj5PPr9Gfm2NO4Uid0slCsUihUJxNl6hOP9XKpV49uIlH7bfsPnoMbnd8gHf98scV6pU6nVqzSZxHBP4Af8bcRJyVD9AeB45s3KCZGx0wOqDsHCFQG81MYwOnjdkKBw8VyCEg3DkKHBsR+G6nvruCIskTsg93P7Ek7d7PP94xNOdX2y+L2PXWtQbIdWKi9HN6HYyWs2M4TBjOs3Isn9RlcYxud3PX9n98o2Tw9/8OTzm9OcxaRgxBSYTkHslaTpb33h1Kbhf3qdcLlOpVqifaZw1zkjnGVfFdDpdyVJQb7bQm0267Q5xFCmyNCWdMx5LxvP5+NoES8FarYZW02g0moxGI8IwZOR5GIaBaZr0+30GgwHmwKTX6+EOXcIgxPf9pdClK6uOOY7CsizVQWE7tFs6un5Op92hfd6m0+limRY9o6eQiWSXPc/Dtm2iKCZJEnJyIYUWCMfBT8Z0bUFXCklB/VxVKA97rstw6BL4vnoCKSIrm0wmlwXVaJoI3yfWGnh7P0iYEvoBQRDMzB4EyvCSKIpWd/mSYL+PYIr/agv//oPZrhu8srLLi7dTgoMBIksJ3+0Qbb2enbrBQldDXXnxdstKhSDxPMauq/yYXrDQVRaWWiAdktM0TVnkYnNs4Shhe57oOjS9h9Yy0NtdTqtVZa+/1XpTbbEsfacAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a9d6e0bfa90059bb24e1173e529f2b16/e4706/F-Secure_02.avif 230w", "/en/static/a9d6e0bfa90059bb24e1173e529f2b16/d1af7/F-Secure_02.avif 460w", "/en/static/a9d6e0bfa90059bb24e1173e529f2b16/56a33/F-Secure_02.avif 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a9d6e0bfa90059bb24e1173e529f2b16/a0b58/F-Secure_02.webp 230w", "/en/static/a9d6e0bfa90059bb24e1173e529f2b16/bc10c/F-Secure_02.webp 460w", "/en/static/a9d6e0bfa90059bb24e1173e529f2b16/e88ff/F-Secure_02.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a9d6e0bfa90059bb24e1173e529f2b16/81c8e/F-Secure_02.png 230w", "/en/static/a9d6e0bfa90059bb24e1173e529f2b16/08a84/F-Secure_02.png 460w", "/en/static/a9d6e0bfa90059bb24e1173e529f2b16/0a47e/F-Secure_02.png 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a9d6e0bfa90059bb24e1173e529f2b16/0a47e/F-Secure_02.png",
              "alt": "F-Security Internet Security",
              "title": "F-Security Internet Security",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Step 3`}</strong>{`: Finally, please reload the web interface of your IP camera. This can be done by either clicking the refresh button next to the address bar of your web browser or you can close your web browser and re-type the IP address of your camera in the browsers address bar. Or restart the InstarVision Surveillance Center.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      